import ReactJsonViewCompare from 'react-json-view-compare';                
// import { useState } from 'react';
function Compare(props){
  const { data1, data2 } = props;
  return (
    <div>
      <ReactJsonViewCompare oldData={data1} newData={data2} />
    </div>
  );
}

export default Compare;
