import React from 'react';
import config from 'configs/config'
import { useState } from 'react';
import { ContentContainer } from '@tmobile/tmo-bps-syncup-web-component-lib';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';
import style from './cmsComparePage.module.css';
import {
  TmoInput,
  TmoDropDownSelect,
  LabelWithInput,
  TmoButtons as TmoButton,
  TmoCheckbox
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import { IoIosArrowDown } from 'react-icons/all';
import cmsPageService from 'services/cmsPageService';
import Authorized from '../../auth/Authorized';
import { APP_MOBILE, SUA_ROLES } from '../../utils/app_constants';
import { FaPlus } from 'react-icons/all';
import Compare from './Compare';
import { ExportJSONDataAsFile } from '@tmobile/tmo-bps-syncup-web-component-lib';




const CmsComparePage = () => {
  
  const [appVersion1, setAppVersion1] = useState();
  const [platform1, setPlatform1] = useState("Android");
  const [environment1, setEnvironment1] = useState("production");
  const [project1, setProject1] = useState("T-life");

  const [appVersion2, setAppVersion2] = useState();
  const [platform2, setPlatform2] = useState("Android");
  const [environment2, setEnvironment2] = useState("production");
  const [checkedbox, setcheckedbox] = useState(false);
  // const [exportDiffbox, setExportDiffbox] = useState(false);
  const [customKey1, setCustomkey1] = useState();
  const [customKey2, setCustomkey2] = useState();
  const [jsonDiff1, setJsonDiff1] = useState();
  const [jsonDiff2, setJsonDiff2] = useState();
  const [jsonComparedVal, setJsonComparedVal] = useState();

  const onLatChange1 = val => {
    setAppVersion1(val);
  };
  const onPlatformChange1 = val => {
    setPlatform1(val);
  };
  const onEnvironmentChange1 = val => {
    setEnvironment1(val);
  };
  const onProjectChange1 = val => {
    setProject1(val);
  };

  const onLatChange2 = val => {
    setAppVersion2(val);
  };
  const onPlatformChange2 = val => {
    setPlatform2(val);
  };
  const onEnvironmentChange2 = val => {
    setEnvironment2(val);
  };
  const onCustomKeyChange1 = val => {
    setCustomkey1(val);
  };
  const onCustomKeyChange2 = val => {
    setCustomkey2(val);
  };
  const handleChange = e => {
    if (e.target.checked) {
      setcheckedbox(true);
    } else {
      setcheckedbox(false);
      setCustomkey1("");
      setCustomkey2("");
    }
  };

 const fetchAndCompare = async () => {
     if (!appVersion1 || !appVersion2) {
         alert("Please enter the app version for both JSONs.");
         return;
     }
     if(checkedbox){
      if (!customKey1 || !customKey2) {
        alert("Please enter the custom key for both JSONs.");
        return;
     }
    }

     const CMS_key1 = customKey1 ? customKey1 : config.cmsKeys[`${platform1}_${environment1}_${project1}`];
     const CMS_key2 = customKey2 ? customKey2 : config.cmsKeys[`${platform2}_${environment2}_${project1}`];
     if (!CMS_key1 || !CMS_key2) {
         alert("Invalid CMS key combination.");
         return;
     }
     const outputDiv = document.getElementById('diff');
    const bodyRedBuild = {
      version: appVersion1,
      url: config.cmsKeys.cmsKeyUrl,
      key: CMS_key1,
    };
    const bodyGreenBuild = {
      version: appVersion2,
      url: config.cmsKeys.cmsKeyUrl,
      key: CMS_key2,
    };
    try {
        const responses1 = await cmsPageService.getCmsResult({
          syncUpAppName: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH, APP_MOBILE.DRIVE],
          body: bodyRedBuild
        });
        const responses2 = await cmsPageService.getCmsResult({
          syncUpAppName: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH, APP_MOBILE.DRIVE],
          body: bodyGreenBuild
        });

        // Promise.allSettled([
        //   cmsPageService.getCmsResult({
        //     url: urlRedBuild,
        //   syncUpAppName: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH, APP_MOBILE.DRIVE],
        //   }),
        //   cmsPageService.getCmsResult({
        //     url: urlGreenBuild,
        //   syncUpAppName: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH, APP_MOBILE.DRIVE],
        //   }),
        
        // ]);
        
      
        const jsonRedBuil = await responses1.result;
        const jsonGreenBuild = await responses2.result;
        // const jsonRedBuil ="{\"translationVersion sathish\":3606,\"global\":{\"defaultLanguage\":\"en\",\"configInstance\":{\"name\":\"tmus-staging-android\",\"version\":\"1.0.0\"}},\"version\":0,\"modules\":{\"cms\":{\"supportedLanguages\":[{\"name\":\"T-World\",\"code\":\"en\",\"isoCode\":\"en\",\"countryCode\":\"us\",\"externalPlatformCode\":\"en-US\"}],\"availableLanguages\":[\"en\"]}}}"
        // const jsonGreenBuild = "{\"translationVersion\":3606,\"global kumar\":{\"defaultLanguage\":\"en\",\"configInstance\":{\"name\":\"tmus-staging-android\",\"version\":\"1.0.0\"}},\"version\":0,\"modules\":{\"cms\":{\"supportedLanguages\":[{\"name\":\"T-World\",\"code\":\"en\",\"isoCode\":\"en\",\"countryCode\":\"us\",\"externalPlatformCode\":\"en-US\"}],\"availableLanguages\":[\"en\"]}}}"


         const json1ResponseValue= JSON.parse((jsonRedBuil));
         const json2ResponseValue = JSON.parse((jsonGreenBuild));
 
         // Display JSON response in respective containers
         document.getElementById("json1ResponseText").textContent = JSON.stringify(json1ResponseValue)
         document.getElementById("json2ResponseText").textContent =  JSON.stringify(json2ResponseValue)

 // Parse JSON inputs
//  const objRedBuild = json1ResponseValue;
//      const objGreenBuild = json2ResponseValue;
 
     // Clear previous output
     outputDiv.innerHTML = '';
 
 // Initialize jsondiffpatch and get differences
//  var jsondiffpatch = require('jsondiffpatch');
//  var formatters = require('jsondiffpatch/src');
//  const diffpatcher = jsondiffpatch.create();
//  const delta = jsondiffpatch.diff(objRedBuild, objGreenBuild);
 
 if (json1ResponseValue || json2ResponseValue) {
  setJsonDiff1(json1ResponseValue);
  setJsonDiff2(json2ResponseValue);
  setJsonComparedVal(document.getElementById('diff').innerText);
//  console.log(delta);
//  diff = jsondiffpatch.diff(objRedBuild, objGreenBuild);
        //  const diffHtml = formatters(diff);
        //  outputDiv.innerHTML= diff;
      } else {
        outputDiv.innerHTML = "<p>No differences found. The JSON responses are identical.</p>";
      }
      
    } catch (error) {
      // alert("Error fetching JSON data. Check the URLs or your network connection.");
      console.error(error);
    }
  };
 function copyToClipboard(elementId) {
 // Get the text content of the specified element
 const text = document.getElementById(elementId).innerText;
 // Copy text to clipboard
 navigator.clipboard.writeText(text).then(() => {
 alert('Copied to clipboard!');
 }).catch(err => {
 console.error('Failed to copy: ', err);
 });
 }
 
 
//  function transformDiffToExcelFormat(diff) {
//  const rows = [];
 
//  const processDiff = (obj, path = []) => {
//  for (const key in obj) {
//      const value = obj[key];
 
//      if (Array.isArray(value) && value.length === 2 && value[0] !== value[1]) {
//          // Modified value
//          rows.push({
//              Path: path.concat(key).join('.'),
//              Type: "Modified",
//              OldValue: value[0],
//              NewValue: value[1],
//          });
//      } else if (Array.isArray(value) && value.length === 1) {
//          // Added value
//          rows.push({
//              Path: path.concat(key).join('.'),
//              Type: "Added",
//              OldValue: null,
//              NewValue: value[0],
//          });
//      } else if (value === 0) {
//          // Removed value
//          rows.push({
//              Path: path.concat(key).join('.'),
//              Type: "Removed",
//              OldValue: obj[key][0],
//              NewValue: null,
//          });
//      } else if (typeof value === 'object' && value !== null) {
//          // Nested object
//          processDiff(value, path.concat(key));
//      }
//  }
//  };
 
//  processDiff(diff);
//     return rows;
//  }
 
 
//  function exportDiffToExcel(diff) {
//  const data = transformDiffToExcelFormat(diff);
 
//  if (data.length === 0) {
//     alert("No differences to export.");
//     return;
//  }
//  var XLSX = require("xlsx");
//  const worksheet = XLSX.utils.json_to_sheet(data);
//  const workbook = XLSX.utils.book_new();
//  XLSX.utils.book_append_sheet(workbook, worksheet, "Differences");
//  XLSX.writeFile(workbook, "JSON_Differences.xlsx");
//  }
 
 
//  document.getElementById("exportButton").addEventListener("click", () => {
// });
// const exportModal =()=>{
//    exportDiffToExcel(diff); // Assuming `diff` is globally accessible
//  }
 
const selectOptions = [
  { key: 'Android', value: 'Android' }, { key: 'iOS', value: 'iOS' }
];
const selectOptionsProj1 = [
  { key: 'T-life', value: 'T-life' }, { key: 'Metro', value: 'Metro' }
];
const selectOptionsEnv1 = [
  { key: 'prd', value: 'Production' }, { key: 'stg', value: 'Stage' },{ key: 'dev', value: 'Dev'}
];const selectOptions2 = [
  { key: 'Android', value: 'Android' }, { key: 'iOS', value: 'iOS' }
];
const selectOptionsEnv2 = [
  { key: 'prd', value: 'Production' }, { key: 'stg', value: 'Stage' },{ key: 'dev', value: 'Dev'}
];



  return (
    <Authorized
    roles={[SUA_ROLES.ADMIN, SUA_ROLES.TLIFE_CMS_ADMINS]}
    willShowErrorModal
  >
    <ContentContainer>
      <PageHeader title={'Cms Compare'}></PageHeader>
      <div className={style.container}>
         <h2>CMS configuration comparsion tool</h2>
         <p className='pTagStyle'>
            When performing release candidate comparisons, ensure to first compare the old release version with the current release to verify values are carried forward correctly. And then compare the current release stage environment with the release environment to validate all new configuration values are added correctly.
         </p>
         <div className='leftSide'>
       
               <div className={style.dropdownProject}>
               <label className={style.label}>Custom CMS Key </label>
               <TmoCheckbox
               className={style.checkboxEdit}
                onChange={e => handleChange(e)}
                isChecked={checkedbox}
                checkbox="magentaCheckbox"
              />
               </div>
              
{!checkedbox &&
<div className={style.dropdownProject}>
               <label className={style.label}>Project </label>
                  <TmoDropDownSelect
                    className={style.custom_dropdown}
                    optionsList={selectOptionsProj1}
                    renderIcon={<IoIosArrowDown />}
                    defaultSelectedValue={selectOptionsProj1[0]}
                    onChange={onProjectChange1}
                  />
               </div>
}
            <div className={style.dropdownGroup}>
               {checkedbox &&
         <div>
           
                  <LabelWithInput
                  labelText="Enter CMS Key"
                  formComponent={TmoInput}
                  name="customKey1"
                  inputType="primary"
                  id="customKey1"
                  className={style.file_input}
                  placeholder="Enter Custom CMS Key"
                  value={customKey1}
                  onChange={onCustomKeyChange1}
                />
               </div> 
}
              {!checkedbox &&
               <div className={style.dropdown}>
                  <label className={style.label}>Platform </label>
                  <TmoDropDownSelect
                    className={style.custom_dropdown}
                    optionsList={selectOptions}
                    renderIcon={<IoIosArrowDown />}
                    defaultSelectedValue={selectOptions[0]}
                    onChange={onPlatformChange1}
                  />
               </div>
}
{!checkedbox &&

               <div className={style.dropdown}>
                   <label className={style.label}>Environment </label>
                  <TmoDropDownSelect
                    className={style.custom_dropdown}
                    optionsList={selectOptionsEnv1}
                    renderIcon={<IoIosArrowDown />}
                    defaultSelectedValue={selectOptionsEnv1[0]}
                    onChange={onEnvironmentChange1}
                  />
               </div>
}
               <div className={style.dropdown}>
                  <LabelWithInput
                  labelText="App Version"
                  formComponent={TmoInput}
                  name="appVersion1"
                  inputType="primary"
                  id="appVersion1"
                  className={style.file_input}
                  placeholder="Enter app version (e.g., 1.0.0)"
                  value={appVersion1}
                  onChange={onLatChange1}
                />
               </div>
               <div className={style.jsonContainer} id="json1Response">
                  <pre id="json1ResponseText"></pre>
               </div>
              <TmoButton
              type="magenta_secondary"
              className={style.clipboardButton}
              onClick={()=>copyToClipboard("json1ResponseText")}
              icon={<FaPlus />}
              tooltip="Copy to Clipboard"

            >
              Copy to Clipboard
            </TmoButton>
            </div>
            <div className={style.dropdownGroup}>
               {checkedbox &&
         <div >
           
                  <LabelWithInput
                  labelText="Enter CMS Key"
                  formComponent={TmoInput}
                  name="customKey2"
                  inputType="primary"
                  id="customKey2"
                  className={style.file_input}
                  placeholder="Enter Custom CMS Key"
                  value={customKey2}
                  onChange={onCustomKeyChange2}
                />
               </div> 
}
            {!checkedbox &&
               <div className={style.dropdown}>
               <label className={style.label}>Platform </label>
                  <TmoDropDownSelect
                    className={style.custom_dropdown}
                    optionsList={selectOptions2}
                    renderIcon={<IoIosArrowDown />}
                    defaultSelectedValue={selectOptions2[0]}
                    onChange={onPlatformChange2}
                  />
               </div>}
                {!checkedbox &&
               <div className={style.dropdown}>
               <label className={style.label}>Environment </label>
                  <TmoDropDownSelect
                    className={style.custom_dropdown}
                    optionsList={selectOptionsEnv2}
                    renderIcon={<IoIosArrowDown />}
                    defaultSelectedValue={selectOptionsEnv2[0]}
                    onChange={onEnvironmentChange2}
                  />
               </div>}
               <div className={style.dropdown}>
               <LabelWithInput
                  labelText="App Version"
                  formComponent={TmoInput}
                  name="appVersion2"
                  inputType="primary"
                  id="appVersion2"
                  className={style.file_input}
                  placeholder="Enter app version (e.g., 1.0.0)"
                  value={appVersion2}
                  onChange={onLatChange2}
                />
               </div>
               <div className={style.jsonContainer} id="json2Response">
                  <pre id="json2ResponseText"></pre>
               </div>
               <TmoButton
                type="magenta_secondary"
              className={style.clipboardButton}
              onClick={()=>copyToClipboard("json2ResponseText")}
               icon={<FaPlus />}
              tooltip="Copy to Clipboard"
            >
              Copy to Clipboard
            </TmoButton>  
            </div>
         </div>
         <div className={style.compareJson}>
            <TmoButton
              type="magenta_primary"
              className={style.button}
              onClick={fetchAndCompare}
            >
              Compare JSON
            </TmoButton>
         </div>
         <div id="results">
            <h3>Differences:</h3>
            <div id="diff" className={style.jsonDiff}>
              {(jsonDiff1 || jsonDiff2) ?  
                <Compare data1={jsonDiff1} data2={jsonDiff2} /> : ''
              }
            </div>
            {/* <TmoButton
              type="magenta_primary"
              className={style.button}
              onClick={exportModal}
            >
              Export Differences
            </TmoButton> */}
          <ExportJSONDataAsFile
                buttonType={'magenta_primary'}
                buttonText="Export"
                data={jsonComparedVal}
                fileName={'JSON_Differences'}
                fileExtension="json"
                className={style.export_btn}
                tooltipPosition="top"
              />
         </div> 
      </div>
    </ContentContainer>
    </Authorized>

  );
};
export default CmsComparePage;
